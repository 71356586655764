import { Permission } from '@marketpartner/backend-api';
import AddIcon from '@mui/icons-material/Add';
import { DialogTitle } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { useClient } from 'src/clients/client-context';
import { backend } from 'src/common/api';
import { DialogButton } from "src/common/dialogs/DialogButton";
import { withNotification } from 'src/common/notifications/with-notification';
import { link } from 'src/common/routing/routes';
import { EventForm } from "src/events/EventForm";
import { ifHas } from 'src/login/IfHas';

export const CreateEventButton: FC = () => {
    const client = useClient()!
    const navigate = useNavigate()
    const createMutation = backend.events.useCreateEvent(withNotification({
        onSuccess: (_, [_clientId, eventDetails]) => navigate(link.toEvent(client.id, eventDetails.id)),
        successMessage: "Created event",
        errorMessage: "Error creating event"
    }))

    return ifHas(Permission.AdministerSystem, client.id, undefined,
        <DialogButton
            icon={<AddIcon />}
            text="Create event"
            variant="contained"
            color="primary"
        >
            <DialogTitle>Create event</DialogTitle>
            <EventForm
                actionName="Create"
                onSubmit={formData => createMutation.mutateAsync([client.id, formData])}
            />
        </DialogButton>
    )
}