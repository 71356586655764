import { Scope } from "@marketpartner/backend-api";
import { DialogTitle, Grid } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { EmailTemplateSelector } from "src/email/EmailTemplateSelector";
import { useEvent } from "src/events/event-context";


export type EventMeetingSettingsDialogProps = LockableDialogProps

const EventMeetingSettingsDialog: FC<EventMeetingSettingsDialogProps> = ({
    ...props
}) => {
    return <LockableDialog
        {...props}
        title="Meeting settings"
    >
        <DialogTitle>
            Meeting settings
        </DialogTitle>
        <EventMeetingSettingsForm />
    </LockableDialog>
}

const EventMeetingSettingsForm: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const [requestedTemplateId, setRequestedTemplateId] = useState(event.emailTemplates.meetingRequested)
    const [acceptedTemplateId, setAcceptedTemplateId] = useState(event.emailTemplates.meetingAccepted)
    const updateEventMutation = backend.events.useUpdateEvent()

    return <DialogForm
        actionName="Save"
        formData={{}}
        onSubmit={() => updateEventMutation.mutateAsync([client.id, event.id, {
            emailTemplates: {
                meetingRequested: requestedTemplateId,
                meetingAccepted: acceptedTemplateId,
            },
        }])}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <EmailTemplateSelector
                    label="Meeting requested email"
                    selectedId={requestedTemplateId}
                    onSelectId={setRequestedTemplateId}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <EmailTemplateSelector
                    label="Meeting accepted email"
                    selectedId={acceptedTemplateId}
                    onSelectId={setAcceptedTemplateId}
                    fullWidth
                />
            </Grid>
        </Grid>
    </DialogForm>
}

export const useEventMeetingSettingsDialog = createPopupHook({
    scope: Scope.Event,
    element: EventMeetingSettingsDialog,
})