import { Permission } from "@marketpartner/backend-api"
import { Edit } from "@mui/icons-material"
import { IconButton, Stack, SxProps } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { BasicProperties, BasicProperty } from "src/common/layout/BasicProperties"
import { EmailTemplateName } from "src/email/EmailTemplateName"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { useEditMessagingConfigurationDialog } from "src/networking/messaging/EditMessagingConfigurationDialog"


export type MessagingDetailsProps = {
    sx?: SxProps
}

export const MessagingDetails: FC<MessagingDetailsProps> = ({
    sx,
}) => {
    const client = useClient()!
    const event = useEvent()!

    const editDialog = useEditMessagingConfigurationDialog()

    return <Stack sx={{
        ...sx,
        p: 2,
    }}>
        <BasicProperties>
            <BasicProperty
                name="Notification email template"
                value={<EmailTemplateName id={event.emailTemplates.messageNotification} />}
            />
            {event.emailTemplates.messageNotification && <>
                <BasicProperty
                    name="Email grace period"
                    value={`${event.messageNotificationEmailGracePeriodMinutes.toLocaleString()} minutes`}
                />
                <BasicProperty
                    name="Maximum email frequency"
                    value={`Once every ${(event.messageNotificationEmailMaxFrequencyMinutes).toLocaleString()} minutes`}
                />
            </>}
            {ifHas(Permission.EditEventDetails, client.id, event.id, <IconButton
                children={<Edit />}
                color="primary"
                onClick={() => editDialog.open({})}
            />)}
        </BasicProperties>
    </Stack>
}