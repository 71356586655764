import { Permission } from "@marketpartner/backend-api"
import { AddAlarm, Bolt } from "@mui/icons-material"
import { Button, SxProps } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Spacer } from "src/common/layout/Spacer"
import { useEmailTriggersDialog } from "src/email/EmailTriggersDialog"
import { useNewScheduledEmailDialog } from "src/email/scheduling/NewScheduledEmailDialog"
import { ScheduledEmailsGrid } from "src/email/scheduling/ScheduledEmailsGrid"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"


export type EmailsProps = {
    sx?: SxProps
}

export const Emails: FC<EmailsProps> = ({
    sx
}) => {
    const client = useClient()!
    const event = useEvent()!

    const triggersDialog = useEmailTriggersDialog()
    const scheduleDialog = useNewScheduledEmailDialog()

    return <BarAndFlex sx={sx} barContent={<Bar p={2} spacing={2}>
        <Spacer />
        {ifHas(Permission.EditEventDetails, client.id, event.id, <Button
            children="Triggers"
            startIcon={<Bolt />}
            //variant="contained"
            onClick={() => triggersDialog.open({})}
        />)}
        {ifHas(Permission.SendEmails, client.id, event.id, <Button
            children="Schedule email"
            onClick={() => scheduleDialog.open({})}
            startIcon={<AddAlarm />}
            variant="contained"
            color="primary"
        />)}
    </Bar>}>
        <ScheduledEmailsGrid />
    </BarAndFlex>
}