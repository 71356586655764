import { FormElementConfig, FormElementMetadata } from "@marketpartner/backend-api"
import { DeepReadOnly, splicedArray } from "@marketpartner/mp-common"
import { useDynamicFormState } from "@marketpartner/mp-common-react"
import { SxProps } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { ReactElement, ReactNode, useState } from "react"
import { LocalPopupContext } from "src/common/dialogs/LocalPopup"
import { BarAndFlex, BarAndFlexProps } from "src/common/layout/BarAndFlex"
import { useBulkReorder } from "src/common/reordering/useBulkReorder"
import { fullSize } from "src/common/styles"
import { FormElementCheck } from "src/forms/elements/checks/form-element-checks"
import { FormElementSourceType } from "src/forms/elements/copying/useFormElementSources"
import { EditableFormElementsContextProvider } from "src/forms/elements/EditableFormElementsContext"
import { EditableFormElementsToolbar } from "src/forms/elements/EditableFormElementsToolbar"
import { FormElementsGrid } from "src/forms/elements/FormElementsGrid"
import { WysiwygEditableFormElements } from "src/forms/elements/WysiwygEditableFormElements"

export type EditableFormElementsProps<Metadata extends FormElementMetadata> = {
    canEdit: boolean
    elements: FormElementConfig<Metadata>[]
    onChangeElements: (elements: FormElementConfig<Metadata>[]) => Promise<void> | void
    defaultSourceType: FormElementSourceType
    commonElements?: FormElementConfig<Metadata>[] | DeepReadOnly<FormElementConfig<Metadata>[]>
    elementChecks?: FormElementCheck<Metadata>[]
    renderExtraControls?: (config: FormElementConfig<Metadata>) => ReactNode
    renderMetadataControls?: (
        config: FormElementConfig<Metadata>,
        onChange: (config: FormElementConfig<Metadata>) => void,
    ) => ReactNode
    defaultMetadata?: Metadata
    sizing?: BarAndFlexProps["sizing"]
    sx?: SxProps
}

export const EditableFormElements = <Metadata extends FormElementMetadata>(
    props: EditableFormElementsProps<Metadata>
): ReactElement => {
    const {
        canEdit,
        elements,
        onChangeElements,
        renderMetadataControls,
        renderExtraControls,
        defaultMetadata,
        sizing,
        sx,
    } = props
    const [showGrid, setShowGrid] = useState(false)

    const modifyElementsMutation = useMutation({
        mutationFn: (elements: FormElementConfig[]) => Promise.resolve(onChangeElements(elements as FormElementConfig<Metadata>[]))
    })
    const formState = useDynamicFormState({
        elements,
        requirement: "complete",
    })
    const reorder = useBulkReorder({
        items: formState.elementStates,
        onReorder: states => modifyElementsMutation.mutateAsync(states.map(it => it.config)),
    })

    const onChangeElementIdx = (idx: number) => (config: FormElementConfig) => modifyElementsMutation.mutateAsync(
        splicedArray(elements, idx, 1, config)
    )
    const onDeleteElementIdx = (idx: number) => () => modifyElementsMutation.mutateAsync(
        splicedArray(elements, idx, 1)
    )
    const onCreateElements = (newElements: FormElementConfig[]) => modifyElementsMutation.mutateAsync(
        [...elements, ...newElements]
    )

    return <EditableFormElementsContextProvider
        canEdit={canEdit}
        elements={elements}
        onChangeElementIdx={onChangeElementIdx}
        onDeleteElementIdx={onDeleteElementIdx}
        onCreateElements={onCreateElements}
        renderMetadataControls={renderMetadataControls}
        renderExtraControls={renderExtraControls}
        defaultMetadata={defaultMetadata}
        reorder={reorder}
    >
        <LocalPopupContext>
            <BarAndFlex
                sizing={sizing}
                component="form" // Stops required fields from blocking the submission of any outer form
                barContent={
                    <EditableFormElementsToolbar
                        showGrid={showGrid}
                        setShowGrid={setShowGrid}
                        onResetForm={formState.reset}
                        {...props}
                    />
                }
                sx={sx}
            >
                {showGrid ?
                    <FormElementsGrid
                        sx={sizing === 'full-size' ? { ...fullSize } : undefined}
                    /> :
                    <WysiwygEditableFormElements
                        sx={sizing === 'full-size' ? { ...fullSize, overflowY: "auto" } : undefined}
                    />
                }
            </BarAndFlex>
        </LocalPopupContext>
    </EditableFormElementsContextProvider >
}