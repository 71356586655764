import { defaultEventFieldElementMetadata, EventFieldEditPermission, EventFieldElementMetadata, EventFieldVisibility, FormElementConfig, isFormField } from "@marketpartner/backend-api"
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { FC } from "react"
import { Bar } from "src/common/layout/Bar"
import { EventFieldEditPermissionIcon, EventFieldVisibilityIcon } from "src/events/fields/EventFieldElementMetadataIcons"


export type EventFieldElementMetadataFormProps = {
    config: FormElementConfig<EventFieldElementMetadata>
    onChange: (config: FormElementConfig<EventFieldElementMetadata>) => void
}

export const EventFieldElementMetadataForm: FC<EventFieldElementMetadataFormProps> = ({
    config,
    onChange,
}) => {
    if (!isFormField(config)) {
        return null
    }

    const metadata = config.metadata ?? defaultEventFieldElementMetadata()
    const setProperties = (properties: Partial<EventFieldElementMetadata>) => {
        onChange({
            ...config,
            metadata: {
                ...metadata,
                ...properties,
            }
        })
    }

    return <>
        <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
            <ToggleButtonGroup
                exclusive
                value={metadata.visibility}
                onChange={(_, value) => setProperties({ visibility: value })}
            >
                {Object.values(EventFieldVisibility).map(visibility => <ToggleButton
                    value={visibility}
                    key={visibility}
                >
                    <Bar spacing={1}>
                        <EventFieldVisibilityIcon elementVisibility={visibility} />
                        <span>{visibilityLabels[visibility]}</span>
                    </Bar>
                </ToggleButton>)}
            </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
            <ToggleButtonGroup
                exclusive
                value={metadata.editPermission}
                onChange={(_, value) => setProperties({ editPermission: value })}
            >
                {Object.values(EventFieldEditPermission).map(editPermission => <ToggleButton
                    value={editPermission}
                    key={editPermission}
                >
                    <Bar spacing={1}>
                        <EventFieldEditPermissionIcon permission={editPermission} />
                        <span>{editPermissionLabels[editPermission]}</span>
                    </Bar>
                </ToggleButton>)}
            </ToggleButtonGroup>
        </Grid>
    </>
}

const visibilityLabels: Record<EventFieldVisibility, string> = {
    Public: "Public",
    RegisteredUsers: "Registered users",
}

const editPermissionLabels: Record<EventFieldEditPermission, string> = {
    AdminOnly: "Admin only",
    EventAdmins: "Event users/admins",
}